import { render, staticRenderFns } from "./SearchRecent.vue?vue&type=template&id=4c823b42&scoped=true&"
import script from "./SearchRecent.vue?vue&type=script&lang=js&"
export * from "./SearchRecent.vue?vue&type=script&lang=js&"
import style0 from "./SearchRecent.vue?vue&type=style&index=0&id=4c823b42&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c823b42",
  null
  
)

export default component.exports