
import Vue from 'vue'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SearchTab from '../../common/tabs/SearchTab.vue' // import Inputs from '../../common/fields/Input.vue'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue' // import StoreHomeDialog from '@/components/views/storeHome/StoreHomeDialog.vue'
import Slider from '../../common/slider/Slider.vue'
import SearchMallCard from '@/components/common/slider/component/SearchMallCard.vue'
import CategoryFilter from '@/components/views/category/common/CategoryFilter.vue'
import { OptionFilterList } from '../../../../types/App'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SearchView',
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CategoryFilter,
    SearchMallCard,
    SearchTab,
    ProductCardY,
    Slider,
  },
  data: () => ({
    debounce: null,
    tab: 1,
    listLength: 20,
    loading: false,
    storeHomeDialog: false,
    storeHomeShopId: '',
    count: 0,
    chipsArr: [],
    filterTab: 2,
    searchParam: {},
  }),
  computed: {
    ...mapGetters('ProfileStore', ['getDefaultFilterIndex', 'getFilterList']),
    ...mapGetters('CategoryStore', ['getCategoryHistory']),
    ...mapGetters('SearchStore', ['getSearchParam']),
    ...mapState('SearchStore', [
      'filterHelper',
      'mallList',
      'relationList',
      'mallTextList',
      'productList',
      'searchRecommendationList',
      'trendHotChip',
      'trendRiseChip',
      'trendHotRankKeywordList',
      'trendThesedayRiseKeywordList',
      'isAutoSave',
      'isShowAutoKeyword',
      'isShowSearchResult',
      'targetList',
      'paging',
      'isMatch',
      'scroll',
    ]),
    computedBestProductText() {
      if (this.productList.length > 0) {
        return `${this.searchWord} 관련 베스트 추천`
      }

      if (this.searchParam) {
        return `${this.searchWord} 관련 베스트 추천`
      }

      return `베스트 상품을 살펴보세요.`
    },
    computedSearchRecommendationList() {
      return this.searchRecommendationList.slice(0, 20)
    },
    searchWord: {
      get() {
        return this.$store.state.SearchStore.searchWord
      },
      set(value) {
        this.$store.commit('SearchStore/setSearchWord', value)
      },
    },
    isSetSearchParam(): boolean {
      return this.chipsArr.length > 0
    },
  },
  watch: {
    tab(value) {
      this.hideKeyboard()
      switch (value) {
        case 0:
          this.fetchGetSearchRank()
          break
        case 1:
          this.fetchGetTrendHotRank()
          this.fetchGetTrendTheseDayRise()
          break
        case 2:
          this.fetchRecentSearch()
          break
      }
    },
    isLogined() {
      this.submit(new KeyboardEvent('keydown', { key: 'Enter' }))

      switch (this.tab) {
        case 0:
          this.fetchGetSearchRank()
          break
        case 1:
          this.fetchGetTrendHotRank()
          this.fetchGetTrendTheseDayRise()
          break
        case 2:
          this.fetchRecentSearch()
          break
      }
    },
    productList() {
      this.count += 1
    },
  },
  async created() {
    //정렬 기본값 설정
    this.searchParam['sort.option'] =
      this.getFilterList[this.getDefaultFilterIndex].filter['sort.option']

    await Promise.all([this.fetchGetTrend()])

    // isAutoSave
    this.setIsAutoSave(JSON.parse(localStorage.getItem('isAutoSave') ?? 'true'))
    if (this.trendHotRankKeywordList.length > 0) {
      this.setTrendHotChip(
        this.trendHotChip.title === ''
          ? this.trendHotRankKeywordList[0]
          : { id: this.trendHotChip.id, title: this.trendHotChip.title }
      )
    } else {
      this.setTrendHotChip({ id: -1, title: '' })
    }
    if (this.trendThesedayRiseKeywordList.length > 0) {
      this.setTrendRiseChip(
        this.trendRiseChip.id === -1
          ? this.trendThesedayRiseKeywordList[0]
          : { id: this.trendRiseChip.id, title: this.trendRiseChip.title }
      )
    } else {
      this.setTrendRiseChip({ id: -1, title: '' })
    }
    this.fetchGetTrendHotRank()
    this.fetchGetTrendTheseDayRise()
  },
  mounted() {
    //스마트 스토어 갔다와도 필터 유지
    if (this.getSearchParam) {
      this.setFilterChips(
        {
          title:
            this.filterHelper?.saleRate?.find(
              (value: SaleRate) =>
                value.filter['filters.minSaleRate'] ===
                Number(this.getSearchParam['filters.minSaleRate'] ?? '')
            )?.title ?? '',
          minSaleRate: this.getSearchParam['filters.minSaleRate'],
          maxSaleRate: this.getSearchParam['filters.maxSaleRate'],
        },
        {
          minPrice: this.getSearchParam['filters.minPrice'],
          maxPrice: this.getSearchParam['filters.maxPrice'],
        }
      )
      this.searchParam['sort.option'] = this.getSearchParam['sort.option']

      this.filterTab =
        this.getFilterList?.findIndex((value) => {
          return (
            value.filter['sort.option'] === this.getSearchParam['sort.option']
          )
        }) ?? this.getDefaultFilterIndex
    }

    this.$getAppHtml.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    this.$getAppHtml.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapMutations('SearchStore', [
      'setSearchWord',
      'setMallList',
      'setRelationList',
      'setProductList',
      'setSearchRecommendationList',
      'setTrendHotChip',
      'setTrendRiseChip',
      'setIsShowAutoKeyword',
      'setIsShowSearchResult',
      'setIsAutoSave',
      'setScroll',
    ]),
    ...mapActions('SearchStore', [
      'fetchSearchParams',
      'fetchRecentSearch',
      'fetchGetSearch',
      'fetchGetSearchInfinity',
      'fetchGetSearchAutoKeyword',
      'fetchGetSearchRank',
      'fetchGetTrendHotRank',
      'fetchGetTrendTheseDayRise',
      'fetchGetSearchRecommendation',
      'fetchClearResponse',
      'fetchGetTrend',
      'fetchSearchTab',
    ]),
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    closeDialog() {
      this.$emit('closeDialog')
    },
    handleSearchInput(e) {
      if (e === null) {
        try {
          this.fetchClearResponse()
        } catch (error) {
          console.log(error)
        }
      }
    },
    async submit(e) {
      // 공백 입력 시에 키보드 내려가는 거 방지
      if (e.key === 'Enter' && this.searchWord.trim() === '') return
      if (e.key === 'Enter' && this.searchWord !== '') {
        await this.fetchRecentSearch()
        await this.fetchGetSearch()
        await this.fetchGetSearchRecommendation()
        ;(document.activeElement as HTMLInputElement).blur()
        return
      }

      if (!this.searchWord) {
        await this.fetchClearResponse()
        return
      }

      try {
        this.setIsShowAutoKeyword(false)
        this.setIsShowSearchResult(false)
        await this.fetchGetSearchAutoKeyword()
      } catch (error) {
        console.log(error)
      } finally {
        this.setIsShowAutoKeyword(true)
      }
    },
    onClickKeyword(searchWord) {
      if (searchWord !== this.$store.state.SearchStore.searchWord) {
        this.resetDetailFilter()
      }
      this.setSearchWord(searchWord)
      this.fetchGetSearch()
      this.fetchGetSearchRecommendation()
    },
    moveToStore(shopId: string) {
      this.$router.push({ name: 'StoreHome', query: { shopId: shopId } })
    },
    moveToStoreForSlider(item: any, index: number) {
      this.moveToStore(item?.link ?? item?.shopId)
    },
    onClickSearchArea() {
      // ;(this.$refs['searchTabs'] as Vue).$data.value = 2
      this.fetchSearchTab(2)
    },
    async onScroll() {
      // scroll save
      if (this.$getAppHtml.scrollTop !== 0) {
        this.setScroll(this.$getAppHtml.scrollTop)
      }
      if (!this.isShowSearchResult) return
      if (!this.productList.length) return
      if (this.loading) return
      if (!this.paging.hasNext) return

      const productHeight = (this.$refs.product as HTMLElement[])[0]
        .clientHeight // 상품 하나 높이
      const leftHeight = Math.floor(productHeight * 5) // 10개 높이, 50개 중, 10개 는 20%

      if (
        this.$getAppHtml.scrollHeight - this.$getAppHtml.scrollTop <
        leftHeight
      ) {
        this.loading = true
        try {
          this.fetchLoading({ yn: true })
          await this.fetchGetSearchInfinity(this.paging.page! + 1)
        } catch (error) {
          console.log('error')
        } finally {
          this.fetchLoading({ yn: false })
          this.loading = false
        }
      }
    },
    goStoreTab() {
      this.fetchMainTab({ tab: 1 })
      this.$router.push('/')
      this.closeDialog()
    },
    onClickInput() {
      // ;(this.$refs['searchTabs'] as Vue).$data.value = 2
      this.fetchSearchTab(2)

      if (this.searchWord === '') {
        this.setIsShowSearchResult(false)
      }
    },
    clearSearchWord() {
      this.searchWord = ''
      this.setIsShowAutoKeyword(false)
      this.setIsShowSearchResult(false)
      this.fetchClearResponse()
      this.resetDetailFilter()
    },
    highlight(searchedWord) {
      const find = this.searchWord
      const regex = new RegExp(find, 'g')
      return searchedWord.trim().replace(
        regex,
        `</span><span
style="color:#003399;">` +
          find +
          '</span><span>'
      ) // highlight class
    },
    moveToBack() {
      this.$tracking('search_back')
      this.$emit('moveToBack')
    },
    hideKeyboard() {
      const keyboard = document.getElementById('keyboard')
      keyboard?.blur()
    },
    async filterTabChange(filterIndex, sortOption) {
      this.filterTab = filterIndex
      this.searchParam['sort.option'] = sortOption['sort.option']
      this.fetchSearchParams(this.searchParam)
      this.fetchGetSearch()
      this.$getAppHtml.scrollTo({ top: 0 })
    },
    setFilterChips(saleRateData, priceData) {
      const res = []
      if (priceData.maxPrice) {
        this.searchParam['filters.minPrice'] = String(priceData.minPrice)
        this.searchParam['filters.maxPrice'] = String(priceData.maxPrice)

        res.push({
          key: 'priceData',
          text: `${priceData.minPrice} ~ ${priceData.maxPrice}`,
        })
      }
      if (saleRateData.minSaleRate && saleRateData.maxSaleRate) {
        this.searchParam['filters.minSaleRate'] = String(
          saleRateData.minSaleRate
        )
        this.searchParam['filters.maxSaleRate'] = String(
          saleRateData.maxSaleRate
        )

        res.push({
          key: 'saleRateData',
          text: `${saleRateData.title} 할인`,
        })
      }
      this.chipsArr = res
    },
    onSubmitFilter(saleRateData, priceData) {
      this.setFilterChips(saleRateData, priceData)

      this.fetchSearchParams(this.searchParam)
      this.fetchGetSearch()
      this.$getAppHtml.scrollTo({ top: 0 })
    },
    async deleteChipArea(key: 'priceData' | 'saleRateData') {
      this.chipsArr.splice(
        this.chipsArr.findIndex((v) => v.key === key),
        1
      )

      switch (key) {
        case 'priceData':
          this.searchParam['filters.minPrice'] = undefined
          this.searchParam['filters.maxPrice'] = undefined
          break
        case 'saleRateData':
          this.searchParam['filters.minSaleRate'] = undefined
          this.searchParam['filters.maxSaleRate'] = undefined
          break
      }

      await this.fetchSearchParams(this.searchParam)
      this.fetchGetSearch()
      this.$getAppHtml.scrollTo({ top: 0 })
    },
    async resetDetailFilter() {
      this.chipsArr = []
      this.searchParam = {
        'filters.maxPrice': undefined,
        'filters.minPrice': undefined,
        'filters.maxSaleRate': undefined,
        'filters.minSaleRate': undefined,
        'sort.option': 'latest',
      }
      this.filterTab = 2
      await this.fetchSearchParams(this.searchParam)
      this.fetchGetSearch()
    },
  },
})

interface Data {
  debounce: number | null
  tab: number
  listLength: number
  loading: boolean
  storeHomeDialog: boolean
  storeHomeShopId: string
  count: number
  chipsArr: any[]
  filterTab: number
  searchParam: SearchParams
}

interface Methods {
  closeDialog(): void
  handleSearchInput(e: InputEvent): void
  submit(e: KeyboardEvent): Promise<void>
  onClickKeyword(searchWord: string): void
  moveToStore(item: any): void
  onClickSearchArea(): void
  onScroll(e: Event): void
  //
  setSearchWord(payload: string): void
  setMallList(payload: any[]): void
  setRelationList(payload: any[]): void
  setProductList(payload: any[]): void
  setSearchRecommendationList(payload: any[]): void
  setTrendHotChip(payload: { id: number; title: string }): void
  setTrendRiseChip(payload: { id: number; title: string }): void
  setIsShowAutoKeyword(payload: boolean): void
  setIsShowSearchResult(payload: boolean): void
  setIsAutoSave(payload: boolean): void
  setScroll(payload: number): void
  fetchSearchParams: (payload: SearchParams) => Promise<void>
  fetchRecentSearch: (payload?: string[]) => Promise<void>
  fetchGetSearch: () => Promise<void>
  fetchGetSearchInfinity: (page?: number) => Promise<void>
  fetchGetSearchAutoKeyword: () => Promise<void>
  fetchGetSearchRank: () => Promise<void>
  fetchGetTrendHotRank: () => Promise<void>
  fetchGetTrendTheseDayRise: () => Promise<void>
  fetchGetSearchRecommendation: () => Promise<void>
  fetchClearResponse: () => Promise<void>
  fetchGetTrend: () => Promise<void>
  goStoreTab(): void
  onClickInput(): void
  clearSearchWord(): void
  fetchMainTab: (payload: { tab: number }) => void
  highlight: (payload: string) => string
  moveToStoreForSlider(item: any, index: number): void
  moveToBack(): void
  hideKeyboard(): void
  fetchSearchTab(payload: number): void
  fetchLoading(payload: { yn: boolean }): Promise<void>
  filterTabChange(
    filterIndex: number,
    sortOption: { 'sort.option': string }
  ): void
  setFilterChips(
    saleRateData: CategorySaleFilter,
    priceData: CategoryPriceFilter
  ): void
  onSubmitFilter(
    saleRateData: CategorySaleFilter,
    priceData: CategoryPriceFilter
  ): void
  deleteChipArea(type: 'priceData' | 'saleRateData'): void
  resetDetailFilter(): void
}
interface Computed {
  searchWord: string
  mallList: SearchAutoKeywordResultMall[]
  relationList: any[]
  productList: any[]
  searchRecommendationList: ProductItem[]
  trendHotChip: { id: number; title: string }
  trendRiseChip: { id: number; title: string }
  trendHotRankKeywordList: { id: number; title: string }[]
  trendThesedayRiseKeywordList: { id: number; title: string }[]
  isAutoSave: boolean
  isShowAutoKeyword: boolean
  isShowSearchResult: boolean
  computedBestProductText: string
  paging: Paging
  isMatch: boolean
  computedSearchRecommendationList: ProductItem[]
  scroll: number
  isSetSearchParam: boolean
  getSearchParam: any
  filterHelper: SearchFilterHelper
  getFilterList: OptionFilterList[]
  getDefaultFilterIndex: number
}
interface Props {
  dialog: boolean
}
