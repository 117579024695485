<template>
  <div class="px-2">
    <div
      v-for="(item, index) in rankList"
      :key="index"
      class="border-bottom d-flex align-center pr-5 mr-2 py-7"
    >
      <div class="d-flex justify-center mr-12" style="width: 20px">
        <span
          :class="`font-g13-15 ${
            index < 5
              ? 'campton-bold blue--text '
              : 'campton-book gray900--text '
          }  ml-5`"
          style="height: 14px"
          >{{ item.rank }}</span
        >
      </div>
      <div>
        <span
          :class="`font-g13-14 ${
            index < 5 ? 'rixgo-bold' : 'rixgo-regular'
          }  gray900--text cursor-pointer`"
          style="height: 13px"
          @click="onClickSearchWord(item.value, index)"
          >{{ item.value }}</span
        >
      </div>
    </div>
    <div class="d-flex pt-10">
      <span class="font-g12-13 campton-medium gray900--text">{{
        refreshTime | moment('YYYY. MM. DD')
      }}</span>
      <span class="font-g12-13 rixgo-regular gray500--text mr-3"
        >&nbsp;기준</span
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'SearchPopular',
  data: () => ({}),
  computed: {
    ...mapState('SearchStore', ['rankList', 'refreshTime']),
  },
  methods: {
    ...mapMutations('SearchStore', ['setSearchWord']),
    ...mapActions('SearchStore', [
      'fetchGetSearch',
      'fetchGetSearchAutoKeyword',
      'fetchGetSearchRecommendation',
    ]),
    onClickSearchWord(item, index) {
      this.$tracking(`search_popular_${this.$numbering(index + 1)}`)
      this.$kakaoPixel('search', { keyword: item })
      this.setSearchWord(item)
      try {
        this.fetchGetSearch()
        this.fetchGetSearchAutoKeyword()
        this.fetchGetSearchRecommendation()
        this.$getAppHtml.scrollTo({ top: 0 })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #f7f8f9;
}
</style>
