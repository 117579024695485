var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{ref:"content",staticClass:"pb-40",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"fixed-bar fill-width",staticStyle:{"border-bottom":"1px solid #f4f4f4"},attrs:{"id":"focus-area"}},[_c('div',{staticClass:"d-flex align-center px-10 white relative",staticStyle:{"height":"60px"},attrs:{"id":"search-input"}},[_c('img',{staticClass:"pb-1",attrs:{"src":require('@/assets/icons/chevr-on-left.svg')},on:{"click":_vm.moveToBack}}),_c('v-text-field',{staticClass:"pr-2",attrs:{"id":"keyboard","background-color":"white","color":`black`,"solo":"","flat":"","hide-details":"","placeholder":"검색어를 입력하세요.","persistent-placeholder":true,"autocomplete":"off"},on:{"input":_vm.handleSearchInput,"click":_vm.onClickInput},nativeOn:{"keyup":function($event){return _vm.submit.apply(null, arguments)}},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}},[_vm._v(" > ")]),(_vm.searchWord)?_c('img',{attrs:{"src":require('@/assets/icons/roundDelete.svg')},on:{"click":_vm.clearSearchWord}}):_vm._e()],1)]),(
      _vm.relationList.length > 0 &&
      _vm.isShowAutoKeyword &&
      _vm.searchWord &&
      !_vm.isSetSearchParam
    )?_c('div',{staticClass:"d-flex flex-wrap px-10 pt-10 pb-14"},_vm._l((_vm.relationList),function(item){return _c('span',{key:item.no,staticClass:"font-g13-14 rixgo-medium blue100 mr-4 mb-4 px-6 py-4",staticStyle:{"color":"#473a72"},on:{"click":function($event){return _vm.onClickKeyword(item.text)}}},[_vm._v(_vm._s(item.text))])}),0):_vm._e(),(
      _vm.mallTextList.length > 0 &&
      _vm.isShowAutoKeyword &&
      _vm.isShowSearchResult === false &&
      _vm.searchWord
    )?_c('div',{staticClass:"divider d-flex flex-wrap px-10 pt-10 pb-10"},[_c('div',{staticClass:"d-flex flex-column fill-width"},[_c('div',{staticClass:"font-g15-16 rixgo-bold gray900--text"},[_c('span',[_vm._v("스토어")])]),_vm._l((_vm.mallTextList),function(item,index){return _c('div',{key:index,staticClass:"border-bottom d-flex justify-space-between align-center pb-7 pt-12 pr-6",on:{"click":function($event){return _vm.moveToStore(item.link)}}},[_c('span',{staticClass:"font-g12-13 rixgo-regular",domProps:{"innerHTML":_vm._s(_vm.highlight(item.title))}}),_c('div',[_c('img',{attrs:{"src":require('@/assets/icons/disable-arr-right.svg')}})])])})],2)]):_vm._e(),(_vm.mallList.length > 0 && (_vm.isShowAutoKeyword || _vm.isShowSearchResult))?_c('div',{staticClass:"divider d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-space-between align-center pt-7 pr-10 pl-10"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"font-g15-16 rixgo-bold gray900--text mr-4"},[_vm._v("스토어")]),_c('div',{staticClass:"d-inline-flex count-container font-g11-12 campton-medium purple--text px-3",staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text-center py-1"},[_vm._v(_vm._s(_vm.mallList.length))])])]),_c('span',{staticClass:"store-text-border font-g11-14 rixgo-regular gray9700--text",on:{"click":_vm.goStoreTab}},[_vm._v("스토어 더보기")])]),_c('div',{staticClass:"px-10-flex hide-scrollbar d-flex overflow-x-auto fill-width pt-8 pb-10"},_vm._l((_vm.mallList),function(item){return _c('div',{key:item.shopId,staticClass:"mr-2"},[_c('SearchMallCard',{attrs:{"item":{
            shopName: item.title,
            subscribeCount: item.num1,
            icon: item.imageUrl,
          }},nativeOn:{"click":function($event){return _vm.moveToStore(item.link)}}})],1)}),0)]):_vm._e(),(_vm.productList.length === 0 && _vm.isShowSearchResult && !_vm.isSetSearchParam)?_c('div',{class:`divider text-container d-flex flex-column
       justify-center align-center text-center`},[_c('span',{staticClass:"font-g14-20 rixgo-bold gray900--text"},[_vm._v("앗! 검색된 상품이 없어요.")]),_c('span',{staticClass:"font-g14-20 rixgo-regular gray900--text"},[_vm._v("다시 검색해보세요.")])]):_vm._e(),(_vm.searchRecommendationList.length > 0)?_c('div',{directives:[{name:"scroll",rawName:"v-scroll:#scroll-target",value:(_vm.onScroll),expression:"onScroll",arg:"#scroll-target"}],staticClass:"d-flex flex-column"},[_c('div',{staticClass:"font-g15-16 rixgo-bold gray900--text mt-10 pl-10"},[_vm._v(" "+_vm._s(_vm.computedBestProductText)+" ")]),_c('Slider',{attrs:{"list":_vm.computedSearchRecommendationList,"sliderInName":"productCardY","swipeSliderClass":"mr-4 py-10","aspectRatio":144 / 180,"itemClass":"product-y-slider","differentItemClass":['pl-5', '', 'mr-5'],"indexKey":"productNo"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[(_vm.isShowSearchResult)?_c('CategoryFilter',{staticClass:"border-top-8px",attrs:{"id":"focus","showFilter":true,"top":"61px","chipsArr":_vm.chipsArr,"filterList":_vm.getFilterList,"filterTab":_vm.filterTab,"filterHelper":_vm.filterHelper,"gtagName":`search_${_vm.searchWord}_detail_filter`},on:{"filterTabChange":_vm.filterTabChange,"onSubmitFilter":_vm.onSubmitFilter,"deleteChipArea":_vm.deleteChipArea}}):_vm._e(),(
        _vm.isShowSearchResult && _vm.isSetSearchParam && _vm.productList.length === 0
      )?_c('div',{staticClass:"text-center pt-38 pb-100"},[_c('div',[_c('div',{staticClass:"font-g14-20 gray700--text"},[_c('span',{staticClass:"rixgo-regular"},[_vm._v(" 검색된 상품이 없어요.")]),_c('div',{staticClass:"rixgo-bold mb-10"},[_vm._v("필터를 다시 설정해보세요.")])]),_c('v-btn',{staticClass:"width-25 font-g12-13 font-weight-400 gray900--text",staticStyle:{"border-color":"#eaebed !important"},attrs:{"height":"35","outlined":""},on:{"click":_vm.resetDetailFilter}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("$vuetify.icons.refreshActiveIcon")]),_c('span',{staticClass:"rixgo-regular"},[_vm._v(" 필터 초기화 ")])],1)],1)]):_vm._e(),(_vm.isShowSearchResult && _vm.productList.length > 0)?_c('div',[_c('div',{class:`d-flex align-center px-10 ${
          _vm.chipsArr.length > 0 ? ' mb-10' : ' my-10'
        }`},[_c('div',{staticClass:"font-g15-16 rixgo-bold gray900--text mr-5"},[_vm._v("상품")]),_c('div',{staticClass:"count-container font-g11-16 campton-semibold blue--text px-3 pt-1"},[_c('span',[_vm._v(_vm._s(_vm._f("handleTransform")(_vm.paging.total)))])])]),_c('div',{ref:"infinite",staticClass:"product-y-grid-two-col fill-width px-10"},_vm._l((_vm.productList),function(item){return _c('div',{key:item.productNo + _vm.count,ref:`product`,refInFor:true},[_c('ProductCardY',{attrs:{"product":item,"oneLine":false}})],1)}),0)]):_vm._e()],1),(_vm.productList.length === 0 && _vm.isShowSearchResult && !_vm.isSetSearchParam)?_c('div',{staticClass:"divider d-flex flex-column pb-15"},[_c('div',{staticClass:"d-flex justify-space-between align-center pt-5 pb-8 ml-10"},[_c('div',{staticClass:"font-g15-16 rixgo-bold gray900--text"},[_vm._v(" 취향저격 몰을 찾아보세요. ")])]),_c('div',{staticClass:"fill-height fill-width pt-8 pb-10"},[_c('Slider',{attrs:{"list":_vm.targetList.slice(0, 10),"sliderInName":"searchMallCard","swipeSliderClass":"mr-2","differentItemClass":['pl-5', '', 'pr-5'],"indexKey":"shopId"},on:{"handleActive":_vm.moveToStoreForSlider}})],1)]):_vm._e(),_c('div',[(!_vm.searchWord)?_c('SearchTab',{ref:"searchTabs",on:{"tabChanged":_vm.hideKeyboard},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }