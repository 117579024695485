<template>
  <div class="d-flex flex-column">
    <div
      v-if="recentSearchList.length === 0"
      :class="`text-container d-flex ${
        !isAutoSave && 'flex-column'
      } justify-center align-center text-center`"
    >
      <span class="font-g14-20 rixgo-bold gray900--text">{{
        computedEmptyText1
      }}</span>
      <span class="font-g14-20 rixgo-regular gray900--text">{{
        computedEmptyText2
      }}</span>
    </div>

    <div
      v-for="(item, index) in recentSearchList"
      :key="index"
      class="border-bottom d-flex justify-space-between align-center py-4 mx-10"
    >
      <span
        class="font-g12-13 rixgo-regular gray900--text oneLine"
        style="height: 13px"
        @click="onClickSearchWord(item, index)"
        >{{ item }}</span
      >
      <v-btn icon small @click="onDeleteSearch(index)">
        <img :src="require('@/assets/icons/delete.svg')" />
      </v-btn>
    </div>
    <v-divider
      v-if="recentSearchList.length === 0"
      class="ma-0 pa-0 border_color"
    />

    <div class="d-flex justify-space-between align-center mx-10">
      <div class="d-flex align-center">
        <div class="mr-2 gray700--text">자동저장</div>
        <v-switch
          v-model="isAutoSave"
          color="black"
          caret-color="white"
          @change="allRemoveSearchWord"
        ></v-switch>
      </div>
      <span
        v-if="isAutoSave && recentSearchList.length > 0"
        class="gray500--text"
        @click="onClick"
        >전체삭제</span
      >
    </div>
    <Confirm
      :value="dialog"
      :content="'최근 검색어를 모두 삭제할까요?'"
      confirmText="삭제"
      @onConfirm="removeAllSearchWords"
      @onClose="dialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Confirm from '../../common/dialogs/Confirm.vue'

export default {
  name: 'SearchRecent',
  components: { Confirm },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState('SearchStore', ['recentSearchList']),
    isAutoSave: {
      get() {
        return this.$store.state.SearchStore.isAutoSave
      },
      set(value) {
        this.$store.commit('SearchStore/setIsAutoSave', value)
      },
    },
    computedEmptyText1() {
      return this.isAutoSave
        ? '최근 검색한 내역'
        : '최근 검색어 자동 저장 기능을 켜고'
    },
    computedEmptyText2() {
      return this.isAutoSave ? '이 없어요' : ' 검색을 편리하게 해보세요.'
    },
  },
  created() {
    this.fetchRecentSearch()
  },
  methods: {
    ...mapMutations('SearchStore', ['setSearchWord']),
    ...mapActions('SearchStore', [
      'fetchRecentSearch',
      'fetchGetSearch',
      'fetchGetSearchAutoKeyword',
      'fetchRecentSearch',
      'fetchGetSearchRecommendation',
    ]),
    onDeleteSearch(index) {
      this.$tracking(
        `search_latest_history_${this.$numbering(index + 1)}_delete`
      )
      const searchWordList = JSON.parse(
        localStorage.getItem('searchWordList') ?? []
      )
      searchWordList.splice(index, 1)
      localStorage.setItem('searchWordList', JSON.stringify(searchWordList))
      this.fetchRecentSearch()
    },
    onClickSearchWord(item, index) {
      this.$tracking(`search_latest_history_${this.$numbering(index + 1)}`)
      this.setSearchWord(item)
      try {
        this.fetchGetSearch()
        // this.fetchGetSearchAutoKeyword()
        this.fetchGetSearchRecommendation()
        this.$getAppHtml.scrollTo({ top: 0 })
      } catch (error) {
        console.log(error)
      }
    },
    allRemoveSearchWord() {
      this.$tracking(`search_latest_history_autosave`)
      if (this.isAutoSave === false) {
        localStorage.setItem('searchWordList', JSON.stringify([]))
        localStorage.setItem('isAutoSave', JSON.stringify(this.isAutoSave))
      } else {
        localStorage.setItem('isAutoSave', JSON.stringify(this.isAutoSave))
      }
      this.fetchRecentSearch()
    },
    removeAllSearchWords() {
      this.$tracking(`search_latest_history_deleteall`)
      localStorage.setItem('searchWordList', JSON.stringify([]))
      this.dialog = false
      this.fetchRecentSearch()
    },
    onClick() {
      this.dialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #f7f8f9;
}
.text-container {
  height: 234px;
}
</style>
