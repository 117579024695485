
import SearchPopular from '../../views/search/SearchPopular.vue'
import SearchTrendHot from '../../views/search/SearchTrendHot.vue'
import SearchRising from '../../views/search/SearchRising.vue'
import SearchRecent from '../../views/search/SearchRecent.vue'
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'SearchTab',
  components: {
    SearchPopular,
    SearchTrendHot,
    SearchRising,
    SearchRecent,
  },
  // props: {
  //   value: {
  //     type: Number,
  //     default: 1,
  //   },
  // },
  data() {
    return {
      items: [
        { tab: '인기검색어', content: '1' },
        { tab: '#Trend', content: '2' },
        { tab: '최근검색어', content: '3' },
      ],
      value: 1,
    }
  },
  created() {
    this.value = this.searchTab
  },
  watch: {
    value(val: number) {
      this.$getAppHtml.scrollTo({ top: 0 })
      this.fetchSearchTab(val)
    },
    searchTab() {
      if (this.value !== this.searchTab) {
        this.value = this.searchTab
      }
    },
  },
  computed: {
    ...mapState('SearchStore', ['searchTab']),
  },
  methods: {
    ...mapMutations('SearchStore', ['setScroll']),
    ...mapActions('SearchStore', [
      'fetchRecentSearch',
      'fetchGetSearch',
      'fetchGetSearchInfinity',
      'fetchGetSearchAutoKeyword',
      'fetchGetSearchRank',
      'fetchGetTrendHotRank',
      'fetchGetTrendTheseDayRise',
      'fetchGetSearchRecommendation',
      'fetchGetTrend',
      'fetchSearchTab',
    ]),
    handleChange(index: number) {
      switch (index) {
        case 0:
          this.$tracking(`search_popular`)
          break
        case 1:
          this.$tracking(`search_trend`)
          break
        case 2:
          this.$tracking(`search_latest`)
          break
      }

      this.value = index
      this.fetch()
    },
    changeTab(value: number) {
      this.fetch()
    },
    fetch() {
      this.$emit('tabChanged')
      const { value } = this
      if (value === 0) {
        this.fetchGetSearchRank()
      } else if (value === 1) {
        this.fetchGetTrendHotRank()
        this.fetchGetTrendTheseDayRise()
      } else if (value === 2) {
        this.fetchRecentSearch()
      }
    },
  },
})
