<template>
  <div class="">
    <div class="d-flex mb-6">
      <div class="mr-auto">
        <div class="font-g20-22 rixgo-extrabold gray900--text">지금 핫한</div>
      </div>
    </div>
    <div class="d-flex flex-wrap pb-7">
      <v-tab style="display: none" />
      <span
        v-for="(item, index) in trendHotRankKeywordList"
        :key="item.id"
        :class="`font-g12-13  ${
          trendHotChip.title === item.title
            ? 'rixgo-bold gray900 default--text'
            : 'rixgo-regular gray100'
        }  pa-4  mr-4 mb-4 `"
        label
        @click="onChangeChip(item, index)"
        >{{ item.title }}</span
      >
    </div>
    <div class="d-flex fill-width align-content-center flex-wrap">
      <div
        v-for="(item, index) in computedHotTrendList"
        :key="item.productNo + key"
        class="width-33 relative"
        style="padding: 0.23%"
      >
        <Product
          :product="item"
          radius="0%"
          :badge="true"
          :aspectRatio="1 / 1"
          :imageInInfo="true"
          imgWrapper="linear-gradient"
          @goProduct="goPage(item, index)"
          @isLikeChange="(yn) => isLikeChange(yn, index)"
        />
      </div>
    </div>
    <div class="d-flex justify-end align-center pt-10" @click="moreShowProduct">
      <span class="font-g12-13 rixgo-regular gray900--text pt-1 pr-2"
        >상품 더보기</span
      >
      <img :src="require('@/assets/icons/right-arrow.svg')" class="mr-2" />
    </div>
  </div>
</template>

<script>
import Product from '../../common/cards/Product.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'SearchTrendHot',
  components: {
    Product,
  },
  data: () => ({
    key: 0,
    activeChipIndex: 0,
  }),
  computed: {
    ...mapState('SearchStore', [
      'hotTrendList',
      'trendHotRankKeywordList',
      'trendHotChip',
    ]),
    computedHotTrendList() {
      return this.hotTrendList
        .filter((hotTrend) => hotTrend.isAvailable)
        .slice(0, 6)
    },
  },
  watch: {
    hotTrendList() {
      this.key += 1
    },
    trendHotRankKeywordList() {
      if (this.trendHotRankKeywordList.length > 0 && !this.trendHotChip.title) {
        this.setTrendHotChip(this.trendHotRankKeywordList[0])
      }
    },
  },
  methods: {
    ...mapMutations('BridgeStore', [
      'setShowBridge',
      'setSrc',
      'setShopId',
      'setProductNo',
      'setMallName',
    ]),
    ...mapMutations('SearchStore', ['setTrendHotChip', 'setSearchWord']),
    ...mapActions('SearchStore', [
      'fetchGetTrendHotRank',
      'fetchGetSearch',
      'fetchGetSearchAutoKeyword',
      'fetchGetSearchRecommendation',
    ]),
    onChangeChip(item, index) {
      this.activeChipIndex = index // tracking 을 위함
      this.$tracking(`search_trend_hot_cate_${this.$numbering(index + 1)}`)
      this.$kakaoPixel('search', { keyword: item.title })
      this.setTrendHotChip(item)
      this.fetchGetTrendHotRank()
    },
    moreShowProduct() {
      this.$tracking(`search_trend_hot_more`)
      this.setSearchWord(this.trendHotChip.title)
      try {
        this.fetchGetSearch()
        this.fetchGetSearchAutoKeyword()
        this.fetchGetSearchRecommendation()
        this.$getAppHtml.scrollTo({ top: 0 })
      } catch (error) {
        console.log(error)
      }
    },
    goPage(item, index) {
      this.$tracking(
        `search_trend_hot_cate_${this.$numbering(
          this.activeChipIndex + 1
        )}_${this.$numbering(index + 1)}`
      )
      this.$rendingProduct(item.productNo)
    },
    isLikeChange(yn, index) {
      const trackingName = `search_trend_hot_cate_${this.$numbering(
        this.activeChipIndex + 1
      )}_${this.$numbering(index + 1)}`

      if (yn) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
}
</script>

<style lang="scss" scoped></style>
