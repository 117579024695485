<template>
  <!-- <transition name="slide-fade"> -->
  <SearchView @moveToBack="moveToBack" />
  <!-- </transition> -->
</template>

<script>
import SearchView from '../../views/search/SearchView.vue'

export default {
  name: 'Search',
  components: {
    SearchView,
  },
  data: () => ({
    show: false,
  }),
  mounted() {
    this.show = true
  },
  methods: {
    moveToBack() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-fade-enter {
  transform: translateX(100vw);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-to {
  transform: translateX(100vw);
  opacity: 0;
}
</style>
