var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2"},[_vm._l((_vm.rankList),function(item,index){return _c('div',{key:index,staticClass:"border-bottom d-flex align-center pr-5 mr-2 py-7"},[_c('div',{staticClass:"d-flex justify-center mr-12",staticStyle:{"width":"20px"}},[_c('span',{class:`font-g13-15 ${
          index < 5
            ? 'campton-bold blue--text '
            : 'campton-book gray900--text '
        }  ml-5`,staticStyle:{"height":"14px"}},[_vm._v(_vm._s(item.rank))])]),_c('div',[_c('span',{class:`font-g13-14 ${
          index < 5 ? 'rixgo-bold' : 'rixgo-regular'
        }  gray900--text cursor-pointer`,staticStyle:{"height":"13px"},on:{"click":function($event){return _vm.onClickSearchWord(item.value, index)}}},[_vm._v(_vm._s(item.value))])])])}),_c('div',{staticClass:"d-flex pt-10"},[_c('span',{staticClass:"font-g12-13 campton-medium gray900--text"},[_vm._v(_vm._s(_vm._f("moment")(_vm.refreshTime,'YYYY. MM. DD')))]),_c('span',{staticClass:"font-g12-13 rixgo-regular gray500--text mr-3"},[_vm._v(" 기준")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }